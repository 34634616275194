// export default [
//   {
//     name: 'image1',
//     type: 'texture',
//     path: './sample/sample_1.jpg',
//   },
//   {
//     name: 'image2',
//     type: 'texture',
//     path: './sample/sample_2.jpg',
//   },
//   {
//     name: 'image3',
//     type: 'texture',
//     path: './sample/sample_3.jpg',
//   },
//   {
//     name: 'image4',
//     type: 'texture',
//     path: './sample/sample_4.jpg',
//   },
//   {
//     name: 'image5',
//     type: 'texture',
//     path: './sample/sample_5.jpg',
//   },
//   {
//     name: 'image6',
//     type: 'texture',
//     path: './sample/sample_6.jpg',
//   },
//   {
//     name: 'image7',
//     type: 'texture',
//     path: './sample/sample_7.jpg',
//   },
//   {
//     name: 'image8',
//     type: 'texture',
//     path: './sample/sample_8.jpg',
//   },
//   {
//     name: 'image9',
//     type: 'texture',
//     path: './sample/sample_9.jpg',
//   },
//   {
//     name: 'image10',
//     type: 'texture',
//     path: './sample/sample_10.jpg',
//   },
//   {
//     name: 'image11',
//     type: 'texture',
//     path: './sample/sample_11.jpg',
//   },
//   {
//     name: 'image12',
//     type: 'texture',
//     path: './sample/sample_12.jpg',
//   },
//   {
//     name: 'image13',
//     type: 'texture',
//     path: './sample/sample_13.jpg',
//   },
//   {
//     name: 'image14',
//     type: 'texture',
//     path: './sample/sample_14.jpg',
//   },
//   {
//     name: 'image15',
//     type: 'texture',
//     path: './sample/sample_15.jpg',
//   },
//   {
//     name: 'image16',
//     type: 'texture',
//     path: './sample/sample_16.jpg',
//   },
//   {
//     name: 'image17',
//     type: 'texture',
//     path: './sample/sample_17.jpg',
//   },
//   {
//     name: 'image18',
//     type: 'texture',
//     path: './sample/sample_18.jpg',
//   },
//   {
//     name: 'image19',
//     type: 'texture',
//     path: './sample/sample_19.jpg',
//   },
//   {
//     name: 'image20',
//     type: 'texture',
//     path: './sample/sample_20.jpg',
//   },
//   {
//     name: 'image21',
//     type: 'texture',
//     path: './sample/sample_21.jpg',
//   },
//   {
//     name: 'image22',
//     type: 'texture',
//     path: './sample/sample_22.jpg',
//   },
//   {
//     name: 'image23',
//     type: 'texture',
//     path: './sample/sample_23.jpg',
//   },
//   {
//     name: 'image24',
//     type: 'texture',
//     path: './sample/sample_24.jpg',
//   },
// ];

// テスト用画像素材
export default [
  {
    name: 'image1',
    type: 'texture',
    path: './origin/1.jpg',
  },
  {
    name: 'image2',
    type: 'texture',
    path: './origin/2.jpg',
  },
  {
    name: 'image3',
    type: 'texture',
    path: './origin/3.jpg',
  },
  {
    name: 'image4',
    type: 'texture',
    path: './origin/4.jpg',
  },
  {
    name: 'image5',
    type: 'texture',
    path: './origin/5.jpg',
  },
  {
    name: 'image6',
    type: 'texture',
    path: './origin/6.jpg',
  },
  {
    name: 'image7',
    type: 'texture',
    path: './origin/7.jpg',
  },
  {
    name: 'image8',
    type: 'texture',
    path: './origin/8.jpg',
  },
  {
    name: 'image9',
    type: 'texture',
    path: './origin/9.jpg',
  },
  {
    name: 'image10',
    type: 'texture',
    path: './origin/10.jpg',
  },
  {
    name: 'image11',
    type: 'texture',
    path: './origin/11.jpg',
  },
  {
    name: 'image12',
    type: 'texture',
    path: './origin/12.jpg',
  },
  {
    name: 'image13',
    type: 'texture',
    path: './origin/13.jpg',
  },
  {
    name: 'image14',
    type: 'texture',
    path: './origin/14.jpg',
  },
  {
    name: 'image15',
    type: 'texture',
    path: './origin/15.jpg',
  },
  {
    name: 'image16',
    type: 'texture',
    path: './origin/16.jpg',
  },
  {
    name: 'image17',
    type: 'texture',
    path: './origin/17.jpg',
  },
  {
    name: 'image18',
    type: 'texture',
    path: './origin/18.jpg',
  },
  {
    name: 'image19',
    type: 'texture',
    path: './origin/19.jpg',
  },
  {
    name: 'image20',
    type: 'texture',
    path: './origin/20.jpg',
  },
  {
    name: 'image21',
    type: 'texture',
    path: './origin/21.jpg',
  },
  {
    name: 'image22',
    type: 'texture',
    path: './origin/22.jpg',
  },
  {
    name: 'image23',
    type: 'texture',
    path: './origin/23.jpg',
  },
  {
    name: 'image24',
    type: 'texture',
    path: './origin/24.jpg',
  },
];
